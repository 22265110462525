import React from 'react'

import Layout from '../components/layout'
import './index.scss'

const IndexPage = ({ pageContext: { locale }, ...props }) => {
  locale = locale.substring(0, 2);
  
  return(<Layout>
    <div className="index-wrapper">
        <a href={'/' + locale + '/imprint'}>{locale === "en" ? "Imprint" : "Impressum"}</a>
    </div>
  </Layout>
)
}
  

export default IndexPage